import Navbar from "../../Navbar.jsx";
import baseURL from "../../../config.js";
import {useNavigate} from "react-router-dom";

export const Informasi = () => {
    const navigate = useNavigate();

    return (
       <div>

           <Navbar/>

           <p className="leading-relaxed text-center font-bold italic mt-4">Halaman Informasi</p>
           <p className="text-center text-xs italic mt-2">(Mohon Maaf Fitur dan Menu lain sedang dalam tahap pembangunan)</p>

           <section className="text-gray-700 body-font">
               <div className="container px-5 py-5 mx-auto">

                   <div className="flex flex-wrap -m-4 text-center">


                       <div className="p-4 w-1/2">
                           <a onClick={()=>{navigate('/list-mustahiq') }}>
                           <div className=" bg-white border-2 border-gray-600 px-4 py-6 rounded-lg transform transition duration-500 hover:scale-110">
                               <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
                                   <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
                                   <circle cx="9" cy="7" r="4"></circle>
                                   <path d="M23 21v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75"></path>
                               </svg>
                               <h2 className="title-font font-medium text-3xl text-gray-900">Mustahiq</h2>
                               <p className="leading-relaxed">Perkelas</p>
                           </div>
                           </a>
                       </div>




                       {/*<div className="p-4 w-1/2">*/}
                       {/*    <div className="border-2 border-gray-600 px-4 py-6 rounded-lg transform transition duration-500 hover:scale-110">*/}
                       {/*        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">*/}
                       {/*            <path d="M3 18v-6a9 9 0 0118 0v6"></path>*/}
                       {/*            <path d="M21 19a2 2 0 01-2 2h-1a2 2 0 01-2-2v-3a2 2 0 012-2h3zM3 19a2 2 0 002 2h1a2 2 0 002-2v-3a2 2 0 00-2-2H3z"></path>*/}
                       {/*        </svg>*/}
                       {/*        <h2 className="title-font font-medium text-3xl text-gray-900">74</h2>*/}
                       {/*        <p className="leading-relaxed">Files</p>*/}
                       {/*    </div>*/}
                       {/*</div>*/}




                       {/*<div className="p-4 md:w-1/4 sm:w-1/2 w-full">*/}
                       {/*    <div className="border-2 border-gray-600 px-4 py-6 rounded-lg transform transition duration-500 hover:scale-110">*/}
                       {/*        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">*/}
                       {/*            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>*/}
                       {/*        </svg>*/}
                       {/*        <h2 className="title-font font-medium text-3xl text-gray-900">46</h2>*/}
                       {/*        <p className="leading-relaxed">Places</p>*/}
                       {/*    </div>*/}
                       {/*</div>*/}



                   </div>
               </div>
           </section>

       </div>
    )
}
// QRCard.jsx
import React from 'react';
import QRCode from 'qrcode.react';
import logo from "../../../logo_ppds.png";

const QRCard = ({ idMurid: nomer_ujian, namaMurid,kelas_murid,marginLeft,id_kelas }) => {


    return (
        <div className={`flex flex-col w-[100mm] h-[59mm] bg-white border border-black ml-${marginLeft}`}>
            <div className="flex items-center">
                <div className="ml-2 mt-1 flex items-center">
                    <img src={logo} alt="Logo PPDS" className="w-10 h-18 mr-4" />
                    <div>
                        <p className="text-lg font-bold">Madrasah Diniyah Darussaadah</p>
                        <p className="text-xs font-semibold">Pondok Pesantren Darussaadah</p>
                    </div>

                </div>

            </div>

            <div className=" mt-2 flex ml-2">
                <div className="mt-1 px-1 pb-1 text-center mr-2 border border-gray-700 rounded">
                    <p className="mt-5 text-xs">Nomor Ujian</p>
                    <p className="text-7xl font-bold p-1">{nomer_ujian}</p>
                    <p className={`${kelasColorMap[id_kelas]}  border border-gray-300 rounded-lg h-7 w-7 `}/>
                </div>
                <div className="text-center ml-2 w-[70mm]">
                    <p className="mt-1 italic text-xs">Semester Awal 1445-1446 H</p>

                    <div className=" px-1.5  text-center  mr-3 border border-gray-700 rounded">
                        <p className="text-l font-bold">{kelas_murid}</p>
                    </div>
                    <div className="mt-1 px-1.5  text-center  mr-3 border border-gray-700 rounded">
                        <p className="text-xs">Nama</p>
                        <p className="text-l font-bold pb-1">{namaMurid}</p>
                    </div>



                    <p style={{ marginTop: '0.25rem', fontStyle: 'italic', fontSize: '0.625rem' }}>
                        Kartu ini hanya sebagai penanda peserta ujian
                    </p>
                    <p style={{  fontStyle: 'italic', fontSize: '0.625rem' }}>
                        Absensi tetap menggunakan KTS
                    </p>
                </div>

            </div>
            {/*<p className="ml-5  italic" style={{ fontSize: '0.6rem' }}>Semester Akhir 1444-1445</p>*/}

        </div>
    );
};

export default QRCard;

const truncateString = (str, num) => {
    if (str.length <= num) {
        return str;
    }
    return str.slice(0, num) + '..';
};
const lk = '800'
const pr = '300'

export const kelasColorMap = {
    '1000': `bg-gray-${lk}`,
    '2000': `bg-red-${lk}`,
    '3000': `bg-green-${lk}`,
    '4000': `bg-fuchsia-${lk}`,
    '5000': `bg-orange-${lk}`,
    '6000': `bg-emerald-${lk}`,
    '7000': `bg-stone-${lk}`,
    '8000': `bg-cyan-${lk}`,
    '1101': `bg-yellow-500`,
    '1102': `bg-purple-${lk}`,
    '2101': `bg-lime-${lk}`,
    '2102': `bg-pink-${lk}`,
    '3101': `bg-sky-${lk}`,
    '3102': `bg-teal-${lk}`,
    '4100': `bg-blue-${lk}`,
    '5100': `bg-rose-${lk}`,
    '6100': `bg-violet-${lk}`,
    '1500': `bg-gray-${pr}`,
    '2500': `bg-red-${pr}`,
    '3500': `bg-green-${pr}`,
    '4500': `bg-fuchsia-${pr}`,
    '5500': `bg-orange-${pr}`,
    '6500': `bg-emerald-${pr}`,
    '7500': `bg-stone-${pr}`,
    '8500': `bg-cyan-${pr}`,
    '1601': `bg-yellow-${pr}`,
    '1602': `bg-purple-${pr}`,
    '2601': `bg-lime-${pr}`,
    '2602': `bg-pink-${pr}`,
    '3601': `bg-sky-${pr}`,
    '3602': `bg-teal-${pr}`,
    '4601': `bg-blue-${pr}`,
    '4602': `bg-rose-${pr}`,
    '5600': `bg-violet-${pr}`,
    '6600': `bg-yellow-800`,
    null: `bg-red-500`,
};


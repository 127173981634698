import React from "react";
import {kelasColorMap} from "./QRCard.jsx";

export const TempelanNomer = ({ nama_murid, idMurid, kelas_murid,marginLeft,no_ujian,id_kelas }) => {
    const last3Digits = idMurid.slice(-3);

    return (
        <div className={`flex flex-col w-[41.2mm] h-[40.5mm]  border border-black ml-${marginLeft} ${kelasColorMapTp[id_kelas]}`}>
            {/*<p className="text-center mt-0.5 italic" style={{ fontSize: '0.4rem' }}>No Ujian Madrasah Diniyah Darussaadah:</p>*/}
            <div className=" mt-2 flex mx-2">
            <div className="text-center w-[41.2mm]">
                <div className={`py-3 border border-gray-700 rounded relative bg-white `}>
                    <p className="text-xs absolute left-1/2 transform -translate-x-1/2 whitespace-nowrap" style={{ fontSize: '0.6rem', top: '0' }}>{nama_murid}</p>
                    <p className="text-7xl font-bold" style={{ WebkitTextStroke: "1px white" }}>
                        {no_ujian}</p>
                    {/*<p className="mt-0.5 italic" style={{ fontSize: '0.35rem' }}>Semester Awal 1445-1446 H</p>*/}
                    <p className="mt-0.5 italic" style={{ fontSize: '0.35rem' }}>${id_kelas}</p>

                </div>
                <div className="mt-1 px-1.5 text-center border border-gray-700 rounded bg-white">
                    <p className="text-xs">{kelas_murid}</p>
                </div>
            </div>
                </div>
        </div>
    );



}

const lk = '600'
const pr = '300'

export const kelasColorMapTp = {
    '1000': `bg-gray-${lk}`,
    '2000': `bg-red-800`,
    '3000': `bg-green-800`,
    '4000': `bg-fuchsia-800`,
    '5000': `bg-orange-800`,
    '6000': `bg-emerald-800`,
    '7000': `bg-stone-800`,
    '8000': `bg-cyan-800`,
    '1101': `bg-yellow-500`,
    '1102': `bg-purple-${lk}`,
    '2101': `bg-lime-${lk}`,
    '2102': `bg-pink-${lk}`,
    '3101': `bg-sky-${lk}`,
    '3102': `bg-teal-${lk}`,
    '4100': `bg-blue-${lk}`,
    '5100': `bg-rose-${lk}`,
    '6100': `bg-violet-${lk}`,
    '1500': `bg-gray-${pr}`,
    '2500': `bg-red-${pr}`,
    '3500': `bg-green-${pr}`,
    '4500': `bg-fuchsia-${pr}`,
    '5500': `bg-orange-${pr}`,
    '6500': `bg-emerald-${pr}`,
    '7500': `bg-stone-${pr}`,
    '8500': `bg-cyan-${pr}`,
    '1601': `bg-yellow-${pr}`,
    '1602': `bg-purple-${pr}`,
    '2601': `bg-lime-${pr}`,
    '2602': `bg-pink-${pr}`,
    '3601': `bg-sky-${pr}`,
    '3602': `bg-teal-${pr}`,
    '4601': `bg-blue-${pr}`,
    '4602': `bg-rose-${pr}`,
    '5600': `bg-violet-${pr}`,
    '6600': `bg-yellow-800`,
    null: `bg-red-500`,
};
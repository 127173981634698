import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import '../gaya.css';
import '../../../App.css';
import baseURL from "../../../config.js";
import Navbar from "../../Navbar.jsx";
import { ArrowPathIcon } from "@heroicons/react/24/solid/index.js";

export const Absensi = () => {
    const [dataFetched, setDataFetched] = useState(false);
    const [timer, setTimer] = useState(null);
    const [search, setSearch] = useState('');
    const [searchResult, setSearchResult] = useState('');
    const [backgroundColor, setBackgroundColor] = useState('bg-green-300');
    const [dbSantri, setDbSantri] = useState([]);
    const searchBarRef = useRef(null);

    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [timer]);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${baseURL}/musama/db-santri`);
            setDbSantri(response.data);
            localStorage.setItem('db_santri', JSON.stringify(response.data));
            setSearchResult(`Data Berhasil diambil pada tanggal : ${new Date().toLocaleString('en-GB')}`);
            setDataFetched(true);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const startTimer = () => {
        setDataFetched(true);
        setTimer(20 * 60);
    };

    const handleSearch = async () => {
        const foundSantri = dbSantri.find(santri => santri.rfid === search);
        if (foundSantri) {
            let resultMessage = (
                <div>
                    <div className="font-bold text-6xl">{foundSantri.nama}</div>
                    <div className="text-xl">{foundSantri.nis}</div>
                    <div className="text-sm">{foundSantri.rfid}</div>
                </div>
            );

            if (timer > 0) {
                setSearchResult(<>{resultMessage}<div className="text-6xl font-bold">Berhasil!</div></>);
                setBackgroundColor('bg-green-300');
            } else {
                setSearchResult(<>{resultMessage}<div className="text-6xl font-bold">Anda Terlambat!</div></>);
                setBackgroundColor('bg-yellow-300');
            }

            try {
                await axios.post(`${baseURL}/musama/post`, foundSantri);
            } catch (postError) {
                console.error('Error posting data:', postError);
            }
        } else {
            setSearchResult(<div className="text-6xl text-white">Data Tidak ditemukan!</div>);
            setBackgroundColor('bg-red-500');
        }
        setSearch('');
        searchBarRef.current.focus();
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <div className={`${backgroundColor} min-h-screen`}>
            <Navbar />
            <div className="container mx-auto p-4">
                <ArrowPathIcon
                    className="p-1 h-10 w-9 mb-3 bg-blue-400 rounded focus:outline-none focus:shadow-outline mx-2"
                    aria-hidden="true"
                    color="white"
                    onClick={() => window.open(`${baseURL}/murid`, '_blank')}
                />
                <button onClick={fetchData} className="mb-3 bg-orange-400 hover:bg-orange-600 text-white text-md py-3.5 px-4 rounded focus:outline-none focus:shadow-outline mx-2">Ambil Data</button>
                <button onClick={startTimer} className="mb-3 bg-blue-400 hover:bg-gray-700 text-white text-lg font-bold py-3 px-4 rounded focus:outline-none mx-2">Mulai absen</button>
                <input
                    ref={searchBarRef}
                    type="text"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Scan RFID"
                    className={`${dataFetched ? 'focus:outline-none mx-2' : 'cursor-not-allowed mx-2'}`}
                />
                <button onClick={handleSearch} disabled={!dataFetched} className={`${dataFetched ? 'bg-yellow-500 hover:bg-yellow-600' : 'bg-gray-400'} text-black text-lg font-bold py-2 px-3 rounded focus:outline-none`}>Cari</button>
                <div className="mt-4 text-xl">{timer > 0 ? `Waktu Tersisa: ${Math.floor(timer / 60)}:${timer % 60 < 10 ? '0' + timer % 60 : timer % 60}` : 'Waktu Habis!'}</div>
                <div className="mt-4">{searchResult}</div>
            </div>
        </div>
    );
};
